"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function addClassOnScroll(targetElement, scrollThreshold, className) {
    function handleScroll() {
        if (window.pageYOffset > scrollThreshold) {
            targetElement.classList.add(className);
        }
        else {
            targetElement.classList.remove(className);
        }
    }
    // ウィンドウのスクロールイベントを監視
    window.addEventListener('scroll', handleScroll);
    // 初期状態のクラスを設定
    handleScroll();
}
exports.default = addClassOnScroll;
