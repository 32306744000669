"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var fixViewport_1 = require("./ts/fixViewport");
// import fadeIn from './ts/fadeIn';
var scrollClass_1 = require("./ts/scrollClass");
require("./scss/style.scss");
document.addEventListener('DOMContentLoaded', function () { (0, fixViewport_1.default)(); });
// document.addEventListener('scroll', () => { fadeIn() });
var targetElement = document.getElementById('menu');
var scrollThreshold = window.pageYOffset + targetElement.getBoundingClientRect().top - 10;
var className = '--fixed';
(0, scrollClass_1.default)(targetElement, scrollThreshold, className);
