"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var viewport = document.querySelector('meta[name="viewport"]');
function fixViewport() {
    var value = window.innerWidth > 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375';
    if (viewport.getAttribute('content') !== value) {
        viewport.setAttribute('content', value);
    }
}
exports.default = fixViewport;
